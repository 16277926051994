import React from "react";
import { CiSearch } from "react-icons/ci";

const SearchSideBar = ({ theme }) => {
  return (
    <div
      className={`${
        theme ? "bg-black" : "bg-white"
      } w-[375px] pt-[3rem] pl-[.7rem]`}
    >
      <h4 className="text-[1.4rem] font-normal">Search</h4>

      <div className="w-[90%] px-[1rem] h-[45px]  mt-5 rounded-[43px]  border-[#8B8D98] border-[.5px] flex items-center ">
        <CiSearch />
        <input
          className="pl-[11px] bg-transparent text-[15px] w-full h-full border-none outline-none  rounded-[43px]"
          placeholder="Search Community"
          name="search"
        />
      </div>

      <h5 className="mt-[1rem] font-normal text-[1rem]">Recent</h5>
    </div>
  );
};

export default SearchSideBar;
